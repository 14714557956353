import { axios } from "@/utils/request";

// 2020107 发送账号验证码
export function sendSelfVerificationCode(data) {
  return axios({
    url: '/customer/sendAddVerificationCode',
    method: 'post',
    data
  })
}

// 2020102 客户注册
export function reg(data) {
  return axios({
    url: '/customer/reg',
    method: 'post',
    data
  })
}

// 2020103 客户登录
export function login(data) {
  return axios({
    url: '/customer/login',
    method: 'post',
    data
  })
}

// 2020104 客户获取信息
export function getCustomerVo(data) {
  return axios({
    url: '/customer/getCustomerVo',
    method: 'post',
    data
  })
}

// 2020105 客户修改信息
export function updateCustomer(data) {
  return axios({
    url: '/customer/updateCustomer',
    method: 'post',
    data
  })
}

// 2020106 忘记修改密码
export function updateForgetPassword(data) {
  return axios({
    url: '/customer/updateForgetPassword',
    method: 'post',
    data
  })
}

// 2020301 获取我的优惠券列表
export function getCustomerCouponPage(data) {
  return axios({
    url: '/customerCoupon/getCustomerCouponPage',
    method: 'post',
    data
  })
}

// 获取字典配置
export function getSetting(data) {
  return axios({
    url: '/dict/getAllDictDataVoList',
    method: 'post',
    data
  })
}

// 获取积分
export function getScore(data) {
  return axios({
    url: '/customer/getCustomerVo',
    method: 'post',
    data
  })
}
