import axios from 'axios'
import {VueAxios} from './axios'
import Vue from 'vue'
import router from '@/router'
import {Toast} from 'vant'

// 创建 axios 实例
const service = axios.create({
  baseURL: '/api', // api base_url
})

const err = (error) => {
  switch (error.response.status) {
    case 401:
      // router.push({name: 'login'})
      break
    case 500:
      // Toast('服务器异常')

      break
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers['user-header'] = JSON.stringify({ token }) // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

import { handleAuth } from "@/utils/auth";
// response interceptor
service.interceptors.response.use((response) => {
  if(response.data.code === '0') {
    return { success: true, data: response.data.data, msg: response.data.msg, count: response.data.count }
  }else if(response.data.code === '3') {
    localStorage.clear()
    handleAuth(true)
  } else {
    return { success: false, msg: response.data.msg, data: { code: response.data.code } }
  }
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
