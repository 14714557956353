<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="less" scoped >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //background: #f1f1f1;
  font-size: 15px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import {
  getSetting
} from '@/api'
export default {
  name: 'App',
  data () {
    return {}
  },
  methods: {
    getSetting() {
      getSetting({ code: 'front_setting' }).then(res => {
        if(res.success) {
          return false
          let data = res.data
          let value = data[0].value
          document.body.style = `filter: grayscale(${value == 1 ? '100%' : '0'})`
        }
      })
    }
  },
  mounted () {
    // localStorage.setItem('token', '6f5707627bd7db86d4d5e4682ea1577d')
    this.getSetting()
  }
}
</script>
